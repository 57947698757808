<template>
  <SourceEditView disabled />
</template>

<script>
import SourceEditView from '@/views/source/SourceEditView'

export default {
  name: 'SourceView',
  components: {
    SourceEditView
  }
}
</script>
